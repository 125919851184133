/**
 * @file standalone/mpp-container.scss
 *
 * \brief Library file - shared styles for mpp container
 *
 */

@import '../theme-bootstrap';

.mpp-container {
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 10px;
  // @todo do we want to account for a standard percentage based padding
  // instead of this hardcoded max width?
  &--has-ugc {
    .mpp-filter {
      &.hide {
        visibility: hidden;
      }
    }
    .mpp-tab-container {
      margin-top: 28px;
    }
    .mpp-tab {
      &__list {
        border-bottom: $border--light;
        border-top: $border--light;
        padding-#{$ldirection}: 30px;
        padding-#{$rdirection}: 0;
        padding-bottom: 4px;
        padding-top: 6px;
        position: relative;
        text-transform: uppercase;
        z-index: 9;
        @include breakpoint($landscape-up) {
          padding-bottom: 6px;
          padding-top: 9px;
        }
      }
      &__item {
        color: $color-off-black;
        cursor: pointer;
        display: inline-block;
        font-weight: 900;
        &:nth-child(n + 2) {
          &:before {
            content: '/';
            display: inline-block;
            padding: 0 2px;
          }
        }
        &.active {
          .mpp-tab__title {
            text-decoration: underline;
          }
        }
      }
      &__content-item {
        margin-top: 5px;
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
        &.active {
          max-height: none;
          overflow: visible;
          visibility: visible;
        }
        .mpp-sort__container {
          #{$rdirection}: 0;
          border: 0;
          margin: 0;
          position: absolute;
          top: -14px;
          @include breakpoint($landscape-up) {
            top: -10px;
          }
        }
        .product-grid-wrapper {
          margin-top: 28px;
        }
      }
    }
    &.mpp-container--has-filters {
      .mpp-sort__container {
        top: 63px;
        @include breakpoint($landscape-up) {
          top: 50px;
        }
      }
    }
  }
  .mpp-filter {
    width: 45%;
    height: 70px;
    @include breakpoint($medium-up) {
      width: 70%;
      height: 50px;
    }
    ~ .mpp-sort__container {
      width: 45%;
      @include breakpoint($medium-up) {
        width: 30%;
      }
      .mpp-sort__select {
        width: 100%;
      }
    }
  }
}

nav.breadcrumbs {
  padding: 0 10px;
  @include breakpoint($landscape-up) {
    padding: 0 40px;
  }
}

.mpp-filter__container {
  margin: 10px;
}
